import { useContext } from "react";
import EnhancedHomepageSlider from "../../components/EnhancedHome/EnhancedHomepageSlider";
import { ShoppingContext } from "../ShoppingContext";
import LoginLoading from "../../components/Spinner/Spinner";
import TransactionHOC from "../../components/Reusable/TransactionHOC";

const DealmedUpsellModal = (props) => {
  const { userSpecificItems, userSpecificItemsLoading } = useContext(ShoppingContext);
  const { closePopover, isAuthenticated } = props

  const sectionTitles = [
    "Buy Again",
    "Based On Your Industry",
    "Pick Up Where You Left Off",
    "Time To Reorder"
  ];

  const itemLists = [
    userSpecificItems?.purchaseAgain ||
    userSpecificItems?.industrySuggestions ||
    userSpecificItems?.recentUserSearchProducts ||
    userSpecificItems?.timeToReorder
  ];

  return (
  <>
    <div className="dealmed-header__cart-blur" 
    onClick={() => {
      closePopover();
    
    }}>

    </div>
    <div className="dealmed-popover-upsell-modal"
  
    >
      {userSpecificItemsLoading ? (
        <div>
          <LoginLoading />
        </div>
      ) : (
        <>
          {itemLists.map((items, index) => {
            if (items && items.length > 0) {
              const enhancedOrdering = !items[0]?.latestPurchase;
              
              return (
                <div  key={index} >
                  <h2 className="dealmed-popover-upsell-modal-header">You May Also Like</h2>
                  <div>
                    <EnhancedHomepageSlider
                      items={items.slice(0, 3)}
                      sectionTitle={`Enhanced_Homepage_${sectionTitles[index].replace(/\s+/g, "_")}`}
                      enhancedOrdering={enhancedOrdering}
                      isAuthenticated={isAuthenticated}
                      isModal={true}
                    />
                  </div>
                </div>
              );
            }
            return null;
          })}
        </>
      )}
    </div>
  </>
  );
};

export default TransactionHOC(DealmedUpsellModal);