import React, { lazy, useCallback, useContext, useEffect } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

// import Home from '../Home/Home';

// import About from '../About/About';
// import ContactUs from '../ContactUs/ContactUs';
// import MarketsServed from '../MarketsServed/MarketsServed';
// import Resources from '../Resources/Resources';


// import ProductsListing from '../Products/ProductsListing';

import ProductDetails from '../ProductDetails/ProductDetails';
// import MedicalSupplyDistribution from '../Content/MedicalSupplyDistribution';

// import SubCategories from '../SubCategories/SubCategories';


// import PaymentMustBeParent from '../Payment/PaymentMustBeParent';
// import PaymentConfirmation from '../Payment/PaymentConfirmation';
// import PaymentError from '../Payment/PaymentError';
// import ShoppingPolicy from '../TermsConditions/ShoppingPolicy';
// import PrivacyPolicy from '../TermsConditions/PrivacyPolicy';

// import PDFDisplay from '../Terms/PDFDisplay'
import { useGate } from 'statsig-react';
import { isMobile } from 'react-device-detect';
import { ContentfulContext } from '../../shared/ContentfulContext';
import GoogleAnalytics from './GoogleAnalytics';
import loadable from '@loadable/component';
import '../../assets/CSS/_globals.scss';
import '../../assets/CSS/styles.scss';
import '@mdi/font/css/materialdesignicons.min.css';
const PrivateRoute = loadable(() => import('./PrivateRoute'));
const ErrorPage = loadable(() => import('../Error/Error'));
const Shop = loadable(() => import('../Shop/Shop'));
const Promotions = loadable(() => import('../Promotions/Promotions'));
const LoginForm = loadable(() => import('../Login/LoginForm'));
const OrderConfirmation = loadable(() => import('../Checkout/OrderConfirmation'));
const Category = loadable(() => import('../Category/Category'));
const OrderError = loadable(() => import('../Checkout/OrderError'));
const Updates = loadable(() => import('../Updates/Updates'));
const Compare = loadable(() => import('../Compare/Compare'));
const Register = loadable(() => import('../Register/Register'));
const Success = loadable(() => import('../Register/Success'));
const GeneralContentPage = loadable(() => import('../Content/GeneralContentPage'));
const Punchout = loadable(() => import('./Punchout'));
const FulfillmentTracking = loadable(() => import('../Tracking/FulfillmentTracking'));
const ProductListingPage = loadable(() => import('../Listing/ProductListingPage'));
const ProductDetailsPage = loadable(() => import('../SingleProduct/ProductDetailsPage'));
const TempHomepage = loadable(() => import('../Content/TempHomepage'));


const Router = (props) => {
	

	const mainNavBarDropdownButtonData = useContext(ContentfulContext)
	.filter(x => x.sys.contentType.sys.id == 'navBar')[0].fields.navBarSections
	.filter(x => x.sys.contentType.sys.id == 'mainNavigation')[0].fields.navigationSections
	.filter(x => x.sys.contentType.sys.id == 'mainNavigationDropdown').flatMap(x => x.fields.pages)
	const mainNavBarButtonData = useContext(ContentfulContext)
	.filter(x => x.sys.contentType.sys.id == 'navBar')[0].fields.navBarSections
	.filter(x => x.sys.contentType.sys.id == 'mainNavigation')[0].fields.navigationSections
	.filter(x => x.sys.contentType.sys.id !== 'mainNavigationDropdown')
	const secondaryNavBarButtonData = useContext(ContentfulContext)
	.filter(x => x.sys.contentType.sys.id == 'navBar')[0].fields.navBarSections
	.filter(x => typeof x.fields.path !== "undefined")
	const contentPageRoutes = useContext(ContentfulContext)
	.find(x => x.sys.contentType.sys.id == 'contentPages').fields.pages
	const websiteRoutes = mainNavBarDropdownButtonData.concat(mainNavBarButtonData, secondaryNavBarButtonData,contentPageRoutes)

	const Checkout = useCallback(
		loadable(() => import('../Checkout/Checkout')),
		[]
	);
	const IssueForm = useCallback(
		loadable(() => import('../BetaBanner/IssueForm')),
		[]
	);
	const Cart = useCallback(
		loadable(() => import('../Cart/Cart')),
		[]
	)

	const MyAccount = useCallback(
		loadable(() => import('../MyAccount/MyAccount')),
		[]
	)
	
	const ProductLookup = useCallback(
		loadable(() => import('../InternalTools/ProductLookup')),
		[]
	)

	const CreatePayment = useCallback(
		// loadable(() => import('../Payment/Payment')),
		// loadable(() => import('../Payment/PaymentNew.jsx')),
		loadable(() => import('../CreatePayment/CreatePayment.jsx')),
		[]
	)
	// const PaymentMethod = useCallback(
	// 	// loadable(() => import('../Payment/PaymentMethod')),
	// 	loadable(() => import('../Payment/PaymentMethodNew.jsx')),
	// 	[]
	// )
	const PaymentReview = useCallback(
		loadable(() => import('../Payment/PaymentReview')),
		[]
	);
	const ReviewOrder = useCallback(
		loadable(() => import('../Checkout/ReviewOrder')),
		[]
	);

	const customerApplicationRedirect = () => {
		window.location.href = 'https://powerforms.docusign.net/38c0920e-e93e-48d2-b733-81d7321c9ad1?env=na1&acct=fb4c8092-e0c9-4bc0-aaac-d219a32bafd0';
	};

	return (
		<>
			<Switch>
				{/* <Route exact path="/" component={Home} /> */}
				<Route exact path="/" component={TempHomepage} />
				<Route exact path="/customerapplication" component={customerApplicationRedirect} />
				<Route exact path="/shop" component={Shop} />
				<Route exact path="/products" render={(props) => <Redirect to={`products/1${props.location.search}`} />} />
				{/* <Route path="/products/:page" component={isMobile? ProductsListing : ProductListingPage} /> */}
				<Route path="/products/:page" component={ProductListingPage} />

				{/* <Route exact path="/category/:catid" component={SubCategories} /> */}
				<Route exact path="/category/:catid" component={Category} />
				
				{/* <Route exact path="/product/:id" component={ProductDetails} /> */}
				<Route exact path="/product/:id" component={ProductDetailsPage} />

				{/* <Route exact path="/contactus" component={ContactUs} /> */}
				{/* <Route exact path="/resources" component={Resources} /> */}
				<Route exact path="/promotions" component={Promotions} />
				{/* <Route exact path="/termsconditions/privacy" component={PrivacyPolicy} />
				<Route exact path="/termsconditions/shopping" component={ShoppingPolicy} /> */}
				<Route exact path="/updates" component={Updates} />
				<Route exact path="/compare" component={Compare} />
				<Route exact path="/register" component={Register} />
				<Route exact path="/success" component={Success} />
				<Route exact path="/tracking/:trackingNumber" component={FulfillmentTracking} />
				{/* <Route exact path="/poterms" component={PDFDisplay} /> */}
				{/* <PrivateRoute exact path="/beta/learn" component={lazy(() => import("../BetaBanner/LearnMore"))} /> */}
				<PrivateRoute exact path="/beta/issue" component={IssueForm} />

				{/* <Route exact path="/login/:hash/:username" component={LoginForm} /> */}
				{/* <Route exact path="/login/:path*" component={LoginForm} /> */}
				<Route exact path="/login" component={LoginForm} />

				<PrivateRoute exact path="/cart" component={Cart} />
				<PrivateRoute exact path="/cart" component={Cart} />
				<PrivateRoute exact path="/checkout" component={Checkout} />
				<PrivateRoute exact path="/subscription" component={Checkout} />
				<PrivateRoute exact path="/reviewOrder" component={ReviewOrder} />
				<PrivateRoute exact path="/orderConfirmation" component={OrderConfirmation} />
				<PrivateRoute exact path="/orderError" component={OrderError} />
				<PrivateRoute exact path="/myaccount" component={MyAccount} />
				<PrivateRoute exact path="/productLookup" component={ProductLookup} />
				{/* <PrivateRoute path="/myaccount/menu/payment" component={CreatePayment} /> */}

				{/* <PrivateRoute exact path="/myaccount/menu/payment/create" component={CreatePayment} /> */}
				{/* <PrivateRoute exact path="/myaccount/menu/payment/mustbeparent" component={PaymentMustBeParent} /> */}
				{/* <PrivateRoute exact path="/myaccount/menu/payment/method" component={PaymentMethod} /> */}
				{/* <PrivateRoute exact path="/myaccount/menu/payment/Review" component={PaymentReview} /> */}
				{/* <PrivateRoute exact path="/myaccount/menu/payment/Confirmation" component={PaymentConfirmation} /> */}
				{/* <PrivateRoute exact path="/myaccount/menu/payment/Error" component={PaymentError} /> */}
				<PrivateRoute path="/myaccount/menu" component={MyAccount} />
				<Route path="/punchout" component={Punchout} />
				<PrivateRoute exact path="/error/:statusCode" component={ErrorPage} />
				{
					websiteRoutes.map((page, index) => (
					<Route key={index} exact path={`${page.fields.path}`} component={() => <GeneralContentPage pageData={page}/>} />
				))
				}
				<Route component={ErrorPage} />
			</Switch>
			<GoogleAnalytics />
		</>
	);
};

export default Router;