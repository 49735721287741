import React from 'react';
import classnames from 'classnames';

const SumIconSVG = ({className}) => {
  return (
    <svg  viewBox="0 0 24 25" className={classnames('dealmed-icon', className)} aria-hidden={true} focusable={false}>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M12.9735 6.80572C12.9735 6.26877 12.5382 5.8335 12.0013 5.8335C11.4644 5.8335 11.0291 6.26877 11.0291 6.80572V12.147L5.97125 12.147C5.4343 12.147 4.99902 12.5823 4.99902 13.1192C4.99902 13.6561 5.4343 14.0914 5.97125 14.0914H11.0291V18.8631C11.0291 19.4001 11.4644 19.8353 12.0013 19.8353C12.5382 19.8353 12.9735 19.4001 12.9735 18.8631V14.0914H18.0286C18.5656 14.0914 19.0009 13.6561 19.0009 13.1192C19.0009 12.5823 18.5656 12.147 18.0286 12.147L12.9735 12.147V6.80572Z" fill="#3B4245"/>
    </svg>
  )
}
export default SumIconSVG