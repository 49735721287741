import React, { useEffect, useCallback } from 'react';
import classnames from 'classnames';
import Spinner from './Spinner';
import DealmedUpsellModal from './DealmedUpsellModal';
import CartHOC from '../../components/Reusable/CartHOC';

const Popover = React.lazy(() => import('@reach/popover'));

const DealmedPopover = (props) => {
    const { 
        innerRef, 
        targetRef, 
        position, 
        closePopover, 
        isOpen, 
        className, 
        children, 
        isContentPopover = false, 
        popoverType,
        cartDetail 
    } = props;

    const handleOutsideClick = useCallback((e) => {
        if (!innerRef?.current || !targetRef?.current) return;

        const clickedOutside = 
            !innerRef.current.contains(e.target) &&
            !targetRef.current.contains(e.target);

        if (clickedOutside) closePopover();
    }, [closePopover, innerRef, targetRef]);

    useEffect(() => {
        document.addEventListener('mousedown', handleOutsideClick, false);
        return () => {
            document.removeEventListener('mousedown', handleOutsideClick, false);
        };
    }, [handleOutsideClick]);

    return (
        <React.Suspense fallback={<Spinner />}>
            <Popover 
                ref={innerRef} 
                targetRef={targetRef} 
                position={position} 
                className={classnames('dealmed-popover', className)} 
                as="div"
            >
                {popoverType === 'shoppingCart' && cartDetail.length && (
                    <DealmedUpsellModal 
                        isOpen={isOpen} 
                        closePopover={closePopover} 
                    />
                )}
                <div
                    className={classnames('dealmed-popover__body', {
                        'dealmed-popover__body--shopping-cart': popoverType === 'shoppingCart' && props.cartDetail.length > 0,
                        'dealmed-popover__body--shopping-cart--empty': popoverType === 'shoppingCart' && props.cartDetail.length < 1,
                        'dealmed-popover__body--override-position': isContentPopover,
                        'dealmed-popover__body--notifications ': popoverType === 'notifications',
                    })}
                >
                    <div className="dealmed-popover__content">{children}</div>
                </div>
            </Popover>
        </React.Suspense>
    );
};

export default CartHOC(DealmedPopover);
