
import React from 'react'
import {
    ClimbingBoxLoader
} from 'react-spinners'
import styles from '../../assets/CSS/_global_variables.scss'

const LoginLoading = () =>
    <div style={{ marginTop: '200px' }} className="d-flex justify-content-center">
        <ClimbingBoxLoader
            size={20}
            color={styles.colorPrimary}
        />
    </div>

export default LoginLoading
