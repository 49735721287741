import React from 'react';
import { NavLink } from 'react-router-dom';


const MainNavigationLink = ({ path, text, onClick }) => {
	const handleClick = () => {
		onClick();
	}

	return (
		<NavLink to={path}  
		onClick={handleClick}>
			<span className="mobile-dealmed-main-navigation__link"
			>{text}</span>
		</NavLink>
	);
};

export default MainNavigationLink;
