import React from 'react';
import classnames from 'classnames';

const CloseSVG = ({ className }) => {
	return (
		<svg width="15" height="17" viewBox="0 0 16 17" className={classnames('dealmed-icon', className)} aria-hidden={true} focusable={false}>
			<path d="M1 1.37988L15 15.3799M15 1.37988L1 15.3799" stroke="#0C0D0E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
		</svg>
	);
};

export default CloseSVG;

