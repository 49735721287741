import BackInStockIcon from '../../shared/icons/BackInStock.svg';
import BackOrderIcon from '../../shared/icons/backOrderIcon.svg';
import DeliveryIcon from '../../shared/icons/DeliveryIcon.svg';
import WarningIcon from '../../shared/icons/WarningIcon.svg';
export const NotificationIcon = ({type}) => {
if (type === 'Item Back in Stock') {
    return (
      <div className="notification-icon">
        <img src={BackInStockIcon} alt="backInStockIcon"/>
      </div>
    )
  } else if (type === 'Backorder Ready') {
    return (
      <div className="notification-icon">
        <img src={BackOrderIcon} alt="backOrderIcon" />
      </div>
    )    
  } else if (type === 'Fulfillment Delivered') {
    return (
      <div className="notification-icon">
        <img src={DeliveryIcon} alt="deliveryIcon" />
      </div>
    )
//   } else if (type === 'warning') {
//     return (
//       <div className="notification-icon">
//         <img src={WarningIcon} alt="warningIcon" />
//       </div>
//     )
  }
  
}