import React from 'react';
import { pathOr } from 'ramda';
import CartHOC from '../Reusable/CartHOC';
import { getUOMConversionRate, getValidUOM, addDefaultSrc } from '../../utils';
import { connect } from 'react-redux';
import { Statsig } from 'statsig-react';
import { mixpanelLogEvent } from '../../utils/mixpanelLogEvent';

const { REACT_APP_STORAGE_URL, REACT_APP_CONT_PRODUCT_IMG, PUBLIC_URL } = process.env;
const IMAGE_URL = REACT_APP_STORAGE_URL + REACT_APP_CONT_PRODUCT_IMG;

const ShoppingCartPopoverProduct = (props) => {
	const { product, isPriceVisible, removeCartDetail } = props;

	const thumbnailImage = pathOr(`${PUBLIC_URL}/images/imageComingSoon.png`, ['thumbnailImage'], product);
	const displayName = (pathOr('', ['siteDisplayName'], product) || pathOr('', ['displayName'], product));
	const price = pathOr('', ['price'], product);
	const quantity = pathOr(1, ['quantity'], product);
	const unit = pathOr(null, ['unit'], product);
	const units = pathOr([], ['units'], product);

	const validUOM = units ? getValidUOM(unit, units) : {};
	const selectedUOM = { label: validUOM.unitName, value: validUOM.id };
	const selectedConversionRate = getUOMConversionRate(unit, units);
	const rate = (selectedConversionRate * price).toFixed(2);

	const matrixDetailTitle= product?.matrixDetails && Object.keys(product?.matrixDetails)[0]
	const matrixDetailType= product?.matrixDetails && Object.values(product?.matrixDetails)[0]

	const removeItemFromCart = (productId) => {
		mixpanelLogEvent({ 
			eventType:"ShoppingCartPopover_Item_Removed_From_Cart", 
			userDetails:props?.auth?.user, 
			itemDetails:product,
			metaData:{
				cartDetails: props.cartDetail || [],
				currentPage: props.location?.pathname,
			} 
		  })

		removeCartDetail(productId)
	}



	return (
		<div className="dealmed-header__cart-product">
			<div className="dealmed-header__cart-image-wrapper">
				<img src={thumbnailImage} className="dealmed-header__cart-image" onError={addDefaultSrc} alt="" loading="lazy"/>
			</div>
			<div className="dealmed-header__cart-details">
				<span className="dealmed-header__cart-name">{displayName}</span>
				<div className='dealmed-header__cart-price-subtitle'>
					{matrixDetailTitle  && <span className='dealmed-header__cart-quantity'>
						{matrixDetailTitle}:{matrixDetailType}{' | '} </span>}
					<span className="dealmed-header__cart-quantity">
					 Qty: {quantity} {selectedUOM.label && ' | ' + " " + selectedUOM.label}
					</span>
				</div>
				<div className="dealmed-header__cart-price-container">
					{isPriceVisible ? <span className="dealmed-header__cart-price">{`$${rate}`}</span> : null}
					<button type="button" className="dealmed-header__cart-remove" onClick={() => removeItemFromCart(product.id)}>
						Remove
					</button>
				</div>
			</div>
		</div>
	);
};
const mapStateToProps = (state) => {
	return {
		auth: state.auth,
	};
};

export default connect(mapStateToProps,)(CartHOC(ShoppingCartPopoverProduct));
