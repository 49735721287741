import React from 'react';
import MinusSVG from '../../shared/icons/MinusThinSVG';
import PlusSVG from '../../shared/icons/PlusThinSVG';

const NumberInput = ({ label, id, name, value, updateValue }) => {
	const handleDecrement = () => {
		const currentValue = Number(value);

		if (isNaN(currentValue) || currentValue - 1 < 1) {
			return;
		}

		updateValue(currentValue - 1);
	};

	const handleIncrement = () => {
		const currentValue = Number(value);

		if (isNaN(currentValue)) {
			return;
		}

		updateValue(currentValue + 1);
	};

	const handleChange = (e) => {
		const newValue = Number(e.target.value);

		if (isNaN(newValue) || newValue < 1) {
			return;
		}

		updateValue(newValue);
	};

	return (
		<div className="dealmed-number-input">
			<button type="button" className="dealmed-number-input__button" onClick={handleDecrement}>
				<span className="visually-hidden">Decrement</span>
				<MinusSVG />
			</button>
			<label className="dealmed-number-input__label" htmlFor={id}>
				<span className="visually-hidden">{label}</span>
				<input type="number" className="dealmed-number-input__input" id={id} name={name} value={value} onChange={handleChange} />
			</label>
			<button type="button" className="dealmed-number-input__button" onClick={handleIncrement}>
				<span className="visually-hidden">Increment</span>
				<PlusSVG />
			</button>
		</div>
	);
};

export default NumberInput;
