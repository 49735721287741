import React from 'react';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { pathOr } from 'ramda';
import MyAccountHOC from '../Reusable/MyAccountHOC';
import InitializeHOC from '../Reusable/InitializeHOC';
import RelatedAccountsDropdown from '../Reusable/RelatedAccountsDropdown';
import { logout, switchAccount } from '../../actions';
import DealmedLogoSVG from '../../shared/icons/DealmedLogoSVG';
import UserCircleSVG from '../../shared/icons/UserCircleSVG';
import CloseSVG from '../../shared/icons/CloseSVG';
import DealmedSVG from '../../shared/icons/DealmedSVG';

const MainNavigationHeader = ({ auth, isAuthenticated, logout, cleanupOnLogout, retrievingRelatedAccounts = false, dispatch, setOpenMenuId }) => {
	const hasRelatedAccounts = pathOr([], ['relatedAccounts'], auth).length > 1;

	const handleLogout = () => {
		logout();
		cleanupOnLogout();
		setOpenMenuId(null);
		dispatch({ type: 'CLOSE_MAIN_NAVIGATION' });
	};

	return (
		<div className="dealmed-main-navigation__header">
			<div className='dealmed-main-navigation__header--wrapper'>

				<div className="dealmed-main-navigation__logo">
					<NavLink to="/" className="dealmed-main-navigation__logo-link" onClick={() => {setOpenMenuId(null); dispatch({ type: 'CLOSE_MAIN_NAVIGATION' })}}>
						<span className="visually-hidden">Homepage</span>
						<DealmedLogoSVG className="dealmed-main-navigation__logo-icon" />
					</NavLink>
				</div>
				<button type="button" className="dealmed-main-navigation__close-btn" onClick={() => {setOpenMenuId(null); dispatch({ type: 'CLOSE_MAIN_NAVIGATION' })}}>
					<span className="visually-hidden">Close Main Navigation</span>
					<CloseSVG className="dealmed-main-navigation__close-btn" />
				</button>
			</div>
			<div className="dealmed-main-navigation__account">
				
				<div className="dealmed-main-navigation__account-bottom">
					{isAuthenticated ? (
						<>
							
							<NavLink to="/myaccount" 
							>
								<UserCircleSVG className="dealmed-main-navigation__account-link--svg"   />
							<span
							className="dealmed-main-navigation__account-link--logged" onClick={() => {setOpenMenuId(null); dispatch({ type: 'CLOSE_MAIN_NAVIGATION' })}}
								>
								{' '}Account & Dealmed Runtime&#8482;{' '} 
								</span>
							</NavLink>
							<input type="button" className="dealmed-main-navigation__account-link dealmed-main-navigation__account-logout" onClick={handleLogout}
							// Temporary, couldn't figure out where the black border and off-white background was coming from
								style={{
									backgroundColor: 'transparent',
									border: 'none',
									color: 'inherit',
									marginLeft:'15px'
								}}
								value={'Logout'}
							/>
							
						</>
					) : (
						<>
							<NavLink to="/myaccount" className="dealmed-main-navigation__account-link--btn" onClick={() => {setOpenMenuId(null); dispatch({ type: 'CLOSE_MAIN_NAVIGATION' })}}>
									Login									
							</NavLink>
								
							<NavLink to="/register" className="dealmed-main-navigation__account-link--btn" onClick={() => {setOpenMenuId(null); dispatch({ type: 'CLOSE_MAIN_NAVIGATION' })}} >
							Create Account
							</NavLink>
						</>
					)}
				</div>
				{isAuthenticated ? (
					hasRelatedAccounts ? (
						<div className="dealmed-main-navigation__account-related">
							{retrievingRelatedAccounts ? <p>Loading related accounts...</p> : <RelatedAccountsDropdown isSearchable />}
						</div>
					) : null
				) : null}
			</div>
			
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		auth: state.auth,
		isAuthenticated: pathOr(false, ['user', 'id'], state.auth),
		retrievingRelatedAccounts: pathOr(false, ['loader', 'retrievingRelatedAccounts'], state),
	};
};

export default connect(mapStateToProps, { logout, switchAccount })(MyAccountHOC(InitializeHOC(MainNavigationHeader)));
